exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".meter_green_oUPv2 {\n    fill: rgb(171, 220, 170);\n    stroke: rgb(174, 211, 168);\n}\n\n.meter_yellow_3uz-j {\n    fill: rgb(251, 219, 130);\n    stroke: rgb(239, 212, 134);\n}\n\n.meter_red_1aMUm {\n    fill: rgb(251, 194, 142);\n    stroke: rgb(235, 189, 142);\n}\n", ""]);

// exports
exports.locals = {
	"green": "meter_green_oUPv2",
	"yellow": "meter_yellow_3uz-j",
	"red": "meter_red_1aMUm"
};